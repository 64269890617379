import { useAlert } from 'react-alert';

import Api, { UnauthenticatedError } from '../api/Api';
import { useAppContext } from '../store';
import { Awaited } from '../typings/utils/Awaited';

type UseApiHandlerConfig = {
  alerts?: boolean;
  throwError?: boolean;
};

type AnyFunction = (...args: any) => any;

type UseApiHandlerParameters<T extends AnyFunction> = {
  config?: UseApiHandlerConfig;
  function: T;
};

export default function useApiHandler<T extends AnyFunction>(props: UseApiHandlerParameters<T>) {
  const appContext = useAppContext();
  const alert = useAlert();

  return async (...args: Parameters<T>) => {
    let result: Awaited<ReturnType<T>> | undefined, error: Error | undefined;
    const { alerts = false, throwError = false } = props.config || {};

    try {
      result = await props.function.call(appContext.api, ...args);
      alerts && result?.message && alert.success(result.message);
    } catch (e) {
      if (e instanceof UnauthenticatedError) {
        await appContext.logout();
      }

      error = e as Error;
      alerts && alert.error(error.message);

      console.error(e);

      if (throwError) {
        throw e;
      }
    }

    return { result, error };
  };
}
