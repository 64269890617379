import { useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { UserSessionInfo } from 'vezro-common';
import Api from '../api/Api';
import useApiHandler from '../hooks/useApiHandler';
import { AppContext } from './index';

export default function GlobalProvider({ children }: React.PropsWithChildren<{}>) {
  const [user, setUser, removeUser] = useLocalStorage<UserSessionInfo>('user');
  const api = useRef(new Api()).current;

  if (!!user) {
    api.loginWithToken(user.token);
  }

  const apiLogin = useApiHandler({
    function: api.login,
    config: {
      alerts: true,
    },
  });

  const apiLogout = useApiHandler({
    function: api.logout,
    config: {
      alerts: true,
    },
  });

  const login = async (userName: string, password: string) => {
    const { result: user } = await apiLogin(userName, password);
    if (user) setUser(user);
  };

  const logout = async () => {
    if (!!user) {
      return apiLogout(user).then(() => {
        removeUser();
      });
    }
  };

  return <AppContext.Provider value={{ user, login, api, logout }}>{children}</AppContext.Provider>;
}
