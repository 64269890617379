import Flyout from '../Flyout';

import { UserAvatar20 } from '@carbon/icons-react';
import { HeaderGlobalAction, Button } from 'carbon-components-react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../store';

export default function UserProfileHeaderAction() {
  const [flyoutOpen, setFlyoutOpen] = useState(false);
  const appContext = useAppContext();
  const history = useHistory();

  const logout = () => {
    appContext.logout().then(() => {
      history.push('/login');
    });
  };

  return (
    <div className="flyout-container">
      <HeaderGlobalAction onClick={() => setFlyoutOpen((state) => !state)}>
        <UserAvatar20 />
      </HeaderGlobalAction>
      <Flyout open={flyoutOpen} dark>
        <div className="user-profile-box">
          <div className="user-profile-box__detail">
            <h4>{appContext.user?.fullName}</h4>
            <p>{appContext.user?.role}</p>
          </div>
          <Button className="user-profile-box__button" kind="secondary" onClick={logout}>
            Logout
          </Button>
        </div>
      </Flyout>
    </div>
  );
}
