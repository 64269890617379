import { ToastNotification } from 'carbon-components-react';
import { AlertComponentProps } from 'react-alert';

export default function Alert({ message, close, id, options }: AlertComponentProps) {
  return (
    <ToastNotification
      closeOnEscape
      onCloseButtonClick={(_) => close()}
      key={id}
      kind={options.type}
      subtitle={<span>{message}</span>}
      timeout={0}
      title={'Notification'}
      caption={''}
      style={{ zIndex: 10000 }}
    />
  );
}
