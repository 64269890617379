import React, { useRef } from 'react';

export default function StatusText({ children }: React.PropsWithChildren<{}>) {
  let color = useRef('red');

  switch (children) {
    case 'SUCCESS':
      color.current = 'green';
      break;
    case 'IN_QUEUE':
      color.current = 'blue';
      break;
  }

  return <span style={{ color: color.current }}>{children}</span>;
}
