import { useState } from 'react';
import { useDebounce } from 'react-use';

export function useDebounced<T>(watchValue: T, timeout: number) {
  const [value, setValue] = useState<T>(watchValue);
  const [isReady, cancel] = useDebounce(
    () => {
      setValue(watchValue);
    },
    timeout,
    [watchValue],
  );

  return value;
}
