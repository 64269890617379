import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppContext } from '../store';

/**
 * Don't use component prop
 */
export default function PrivateRoute({ children, component, ...rest }: React.PropsWithChildren<RouteProps<string>>) {
  let auth = useAppContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          !!children ? (
            children
          ) : (
            component
          )
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
