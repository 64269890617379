import {
  Button,
  DataTable,
  DataTableSkeleton,
  Form,
  Modal,
  NumberInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSelectAll,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TextInput,
  Pagination,
  TableBatchActions,
  ComboBox,
  TableSelectRow,
  FormGroup,
  TableBatchAction,
  Checkbox,
  OverflowMenu,
  OverflowMenuItem,
  TableToolbarAction,
  MultiSelect,
  Row,
} from 'carbon-components-react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import StatusText from '../../../components/StatusText/StatusText';
import ToggleSingleLine from '../../../components/ToggleSingleLine/ToggleSingleLine';
import useApiHandler from '../../../hooks/useApiHandler';
import { useApi } from '../../../store';
import { createPortal } from 'react-dom';
import { Controller, SubmitHandler, useForm, UseFormReturn } from 'react-hook-form';
import { CreatePinBatchDto } from '../../../typings/dto/CreatePinBatch';
import { useDebounce } from 'react-use';
import { useDebounced } from '../../../hooks/useDebounced';
import { Export16 } from '@carbon/icons-react';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { PinBatch } from 'vezro-common';

export default function PinBatchesPage() {
  const getPinBatches = useApiHandler({
    function: useApi().getPinBatches,
    config: {
      throwError: true,
    },
  });

  const exportCSV = useApiHandler({
    function: useApi().exportCSV,
    config: {
      throwError: true,
    },
  });

  const createPinBatch = useApiHandler({
    function: useApi().createPinBatch,
    config: {
      alerts: true,
    },
  });

  const editPinBatch = useApiHandler({
    function: useApi().editPinBatch,
    config: {
      alerts: true,
    },
  });

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState<any>(undefined);
  const [selectedBatchForBrands, setSelectedBatchForBrands] = useState<any>(undefined);
  const [busy, setBusy] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [searchFieldDebounced, setSearchFieldDebounced] = useState('');
  const [queryOptions, setQueryOptions] = useState({
    take: 10,
    skip: 0,
  });

  useDebounce(
    () => {
      setSearchFieldDebounced(searchField);
    },
    1000,
    [searchField],
  );

  const createFormMethods = useForm<CreatePinBatchDto>({
    mode: 'onChange',
  });

  const { data, isLoading, refetch } = useQuery(
    ['pins', searchFieldDebounced, queryOptions],
    async () =>
      getPinBatches({
        take: queryOptions.take,
        skip: queryOptions.skip,
        where: !!searchField
          ? {
              pins: {
                some: {
                  pinCode: {
                    startsWith: searchFieldDebounced,
                  },
                },
              },
            }
          : undefined,
      }),
    {
      select: (data) => {
        return data.result?.result;
      },
    },
  );

  const onCreate = async (data: CreatePinBatchDto) => {
    if (busy) return;
    setBusy(true);
    await createPinBatch(data);
    refetch();
    setBusy(false);
    setCreateModalOpen(false);
  };

  const rows = useMemo(() => {
    return data?.rows?.map((row: any) => {
      return {
        id: row.id,
        name: row.name,
        enable: (
          <ToggleSingleLine
            id={`batch-${row.id}-enable`}
            checked={row.enable}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(event) => {
              editPinBatch({ enable: event.currentTarget.checked, id: row.id }).then((data) => {
                refetch();
              });
            }}
            disabled={row.request.status !== 'SUCCESS'}
          />
        ),
        createdAt: moment(row.createdAt).format('MMM Do, YYYY / hh:mm a'),
        status: <StatusText>{row.request.status}</StatusText>,
        profileName: row.profile.name,
        stats: ` ${row.pins.length} / ${row.pins.filter((e: any) => e.used).length} / ${row.pins.filter((e: any) => !e.enable).length}`,
        menu: (
          <OverflowMenu>
            <OverflowMenuItem
              itemText="Manage Brands"
              onClick={() => {
                setSelectedBatchForBrands(row);
              }}
            />
          </OverflowMenu>
        ),
      };
    });
  }, [data, editPinBatch, refetch]);

  return (
    <Fragment>
      {createPortal(
        <Modal
          open={createModalOpen}
          primaryButtonText="Generate"
          secondaryButtonText="Cancel"
          onRequestClose={() => setCreateModalOpen(false)}
          hasForm={true}
          modalHeading="Create a new Pin Batch"
          modalLabel="Pin Batches"
          size="sm"
          primaryButtonDisabled={busy}
          onRequestSubmit={async () => {
            await createFormMethods.handleSubmit(onCreate)();
          }}
        >
          <PinBatchCreateForm onSubmit={onCreate} {...createFormMethods} />
        </Modal>,
        document.body,
      )}
      {createPortal(
        <Modal
          open={!!selectedBatch}
          onRequestClose={() => {
            setSelectedBatch(undefined);
            setSearchField('');
          }}
          hasForm={true}
          modalHeading="Pins"
          modalLabel="Manage Batch"
          size="lg"
          passiveModal
        >
          <PinsList pinBatch={selectedBatch} defaultSearchField={searchField} />
        </Modal>,
        document.body,
      )}
      {createPortal(
        <Modal
          open={!!selectedBatchForBrands}
          onRequestClose={() => {
            setSelectedBatchForBrands(undefined);
            setSearchField('');
          }}
          hasForm={true}
          modalHeading="Linked Brands"
          modalLabel="Manage Batch"
          size="lg"
          preventCloseOnClickOutside={true}
          passiveModal
        >
          <PinBatchBrands pinBatch={selectedBatchForBrands} />
        </Modal>,
        document.body,
      )}
      {isLoading && <DataTableSkeleton />}
      {!isLoading && data && (
        <DataTable
          isSortable
          rows={rows as any}
          headers={[
            { header: 'ID', key: 'id' },
            { header: 'Name', key: 'name' },
            { header: 'Pins Status', key: 'status' },
            { header: 'Profile', key: 'profileName' },
            { header: 'Pin Stats (Q / U / D)', key: 'stats' },
            { header: 'Enable', key: 'enable' },
            { header: 'Created Date', key: 'createdAt' },
            { header: '', key: 'menu' },
          ]}
        >
          {({ rows, headers, getHeaderProps, selectedRows, getTableProps, getToolbarProps, getBatchActionProps, getSelectionProps }: any) => (
            <TableContainer title="Pin Batches" description="These are all the batches of pins you generated">
              <TableToolbar {...getToolbarProps()}>
                <TableBatchActions {...getBatchActionProps()}>
                  <TableBatchAction
                    renderIcon={Export16}
                    onClick={async () => {
                      const { result } = await exportCSV(selectedRows.map((row: any) => row.id));
                      if (result) {
                        // @ts-ignore
                        const blob = new Blob([result.data], { type: 'text/csv;charset=utf-8' });
                        console.log(blob);
                        // @ts-ignore
                        saveAs(blob, 'export.csv');
                      }
                    }}
                  >
                    Export
                  </TableBatchAction>
                </TableBatchActions>
                <TableToolbarContent>
                  <TableToolbarSearch persistent={true} value={searchField} onChange={(e) => setSearchField(e.target?.value)} />
                  <Button
                    size="small"
                    kind="primary"
                    onClick={() => {
                      createFormMethods.reset({});
                      setCreateModalOpen(true);
                    }}
                  >
                    New Batch
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <TableSelectAll {...getSelectionProps()} />
                    {headers.map((header: any) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => {
                    return (
                      <TableRow
                        key={row.sr}
                        onDoubleClick={(e) => {
                          setSelectedBatch(row);
                        }}
                      >
                        <TableSelectRow {...getSelectionProps({ row })} />
                        {row.cells.map((cell: any) => {
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={Math.round(queryOptions.skip / queryOptions.take) + 1}
                pageNumberText="Page Number"
                pageSize={queryOptions.take}
                pageSizes={[10, 50, 100, 500, 1000]}
                totalItems={data.count}
                onChange={(data) => {
                  setQueryOptions((queryOptions) => {
                    const copy = { ...queryOptions };
                    copy.skip = (data.page - 1) * data.pageSize;
                    copy.take = data.pageSize;
                    return copy;
                  });
                }}
              />
            </TableContainer>
          )}
        </DataTable>
      )}
    </Fragment>
  );
}

const PinsList = (props: { pinBatch: any; defaultSearchField?: string }) => {
  const [searchField, setSearchField] = useState(props.defaultSearchField);
  const [selectedLog, setSelectedLog] = useState(undefined);

  useEffect(() => {
    setSearchField(props.defaultSearchField);
  }, [props.defaultSearchField]);

  const debouncedSearchField = useDebounced(searchField, 1000);

  const getPins = useApiHandler({
    function: useApi().getPins,
    config: {
      throwError: true,
    },
  });

  const editPin = useApiHandler({
    function: useApi().editPin,
    config: {
      throwError: true,
    },
  });

  const renewPin = useApiHandler({
    function: useApi().renewPin,
    config: {
      throwError: true,
    },
  });

  const [queryOptions, setQueryOptions] = useState({
    take: 10,
    skip: 0,
  });

  const { data, isLoading, refetch } = useQuery(
    ['pin', queryOptions, debouncedSearchField],
    async () =>
      getPins({
        take: queryOptions.take,
        skip: queryOptions.skip,
        where: {
          batchId: props.pinBatch?.id,
          pinCode: {
            startsWith: debouncedSearchField,
          },
        },
      }),
    {
      select: (data) => {
        return data.result?.result;
      },
      enabled: !!props.pinBatch,
      initialData: props.pinBatch?.pins,
    },
  );

  const rows = useMemo(() => {
    return data?.rows?.map((row: any) => ({
      sr: `${row.batch.id}-${row.id}`,
      id: row.id,
      pinCode: row.pinCode,
      enable: (
        <ToggleSingleLine
          id={`${row.id}-enable`}
          checked={row.enable}
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onChange={(event) => {
            editPin({ enable: event.currentTarget.checked, id: row.id }).then((data) => {
              refetch();
            });
          }}
        />
      ),
      used: <Checkbox id={`${row.id}-used`} hideLabel labelText="Used" checked={!!row.used} readOnly></Checkbox>,
      renewButton: (
        <Button
          kind="ghost"
          disabled={!row.used}
          onClick={(_) => {
            renewPin(row.id).then((data) => {
              refetch();
            });
          }}
        >
          Renew
        </Button>
      ),
      refundButton: (
        <Button
          kind="ghost"
          disabled={!row.used}
          onClick={(_) => {
            renewPin(row.id, true).then((data) => {
              refetch();
            });
          }}
        >
          Refund
        </Button>
      ),
      viewLogs: (
        <Button
          kind="ghost"
          onClick={(_) => {
            setSelectedLog(row.pinCode);
          }}
        >
          View Logs
        </Button>
      ),
      usedDate: row.usedDate ? moment(row.usedDate).format('MMM Do, YYYY / hh:mm a') : '-',
      expiryDate: row.usedDate ? moment(row.usedDate).add({ days: row.days }).format('MMM Do, YYYY / hh:mm a') : '-',
    }));
  }, [data, editPin, refetch, renewPin]);

  return (
    <Fragment>
      {createPortal(
        <Modal
          open={!!selectedLog}
          onRequestClose={() => {
            setSelectedLog(undefined);
          }}
          hasForm={true}
          modalHeading={`Logs of ${selectedLog}`}
          modalLabel="Manage Pin"
          size="lg"
          style={{ zIndex: 70000 }}
          passiveModal
        >
          <LogView pinCode={selectedLog} />
        </Modal>,
        document.getElementById('modal-root') || document.body,
      )}
      {isLoading && <DataTableSkeleton />}
      {!isLoading && rows ? (
        <DataTable
          isSortable
          rows={rows as any}
          headers={[
            { header: 'Sr.#', key: 'sr' },
            { header: 'Pin', key: 'pinCode' },
            { header: 'Enable', key: 'enable' },
            { header: 'Is Used?', key: 'used' },
            { header: 'Used Date', key: 'usedDate' },
            { header: 'Expiry Date', key: 'expiryDate' },
            { header: 'Renew', key: 'renewButton' },
            { header: 'Refund', key: 'refundButton' },
            { header: '', key: 'viewLogs' },
          ]}
        >
          {({ rows, headers, getHeaderProps, getTableProps, getToolbarProps, getBatchActionProps, selectedRows, getSelectionProps }: any) => (
            <TableContainer>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => {
                      setSearchField(e.target?.value);
                    }}
                  />
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => {
                    return (
                      <TableRow key={row.sr}>
                        {row.cells.map((cell: any) => {
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={Math.round(queryOptions.skip / queryOptions.take) + 1}
                pageNumberText="Page Number"
                pageSize={queryOptions.take}
                pageSizes={[10, 50, 100, 500, 1000]}
                totalItems={data.count}
                onChange={(data) => {
                  setQueryOptions((queryOptions) => {
                    const copy = { ...queryOptions };
                    copy.skip = (data.page - 1) * data.pageSize;
                    copy.take = data.pageSize;
                    return copy;
                  });
                }}
              />
            </TableContainer>
          )}
        </DataTable>
      ) : (
        'No data'
      )}
    </Fragment>
  );
};

const PinBatchCreateForm = ({ onSubmit, ...methods }: UseFormReturn<CreatePinBatchDto> & { onSubmit: SubmitHandler<CreatePinBatchDto> }) => {
  const getProfiles = useApiHandler({
    function: useApi().getProfiles,
    config: {
      throwError: true,
    },
  });

  const { data } = useQuery(
    ['profile'],
    async () =>
      getProfiles({
        take: 1000,
      }),
    {
      select: (data) => {
        return data.result?.result;
      },
    },
  );

  return (
    <Form onSubmit={methods.handleSubmit(onSubmit)}>
      <TextInput
        {...methods.register('name', { required: 'Required' })}
        id="name"
        labelText="Batch Name"
        enterKeyHint="next"
        placeholder="Name for the Batch"
        invalidText={methods.formState.errors.name?.message}
        invalid={!!methods.formState.errors.name?.message}
      />
      <div className="d-flex mt-3">
        <FormGroup legendText="Profile" className="flex-fill pe-3">
          <Controller
            control={methods.control}
            name="profileId"
            rules={{ required: 'Required' }}
            render={({ field, fieldState, formState }) => (
              <ComboBox<any>
                name={field.name}
                onBlur={field.onBlur}
                onChange={(data) => {
                  field.onChange(data.selectedItem?.id);
                }}
                itemToString={(item) => item?.name}
                items={data?.rows || []}
                id="profileId"
                placeholder="Select Profile"
                invalid={!!formState.errors?.profileId}
                invalidText={formState.errors?.profileId?.message}
              />
            )}
          />
        </FormGroup>
        <FormGroup legendText="Count" className="flex-fill ps-3">
          <NumberInput
            {...methods.register('pinsCount', {
              required: 'Required',
              max: {
                value: 10000,
                message: 'Invalid. Should be less than 10000',
              },
              min: {
                value: 10,
                message: 'Invalid. Should be >= 10',
              },
              valueAsNumber: true,
            })}
            id="count"
            value={100}
            invalidText={methods.formState.errors.pinsCount?.message}
            invalid={!!methods.formState.errors.pinsCount?.message}
          />
        </FormGroup>
      </div>
      <button type="submit" className="d-none"></button>
    </Form>
  );
};

const LogView = ({ pinCode }: { pinCode?: number }) => {
  const getPinLogs = useApiHandler({
    function: useApi().getPinLogs,
    config: {
      throwError: true,
    },
  });

  const [queryOptions, setQueryOptions] = useState({
    take: 10,
    skip: 0,
  });

  const { data, isLoading } = useQuery(
    ['logs', pinCode],
    async () =>
      getPinLogs({
        ...queryOptions,
        where: {
          pin: pinCode ? { pinCode: String(pinCode) } : undefined,
        },
      }),
    {
      select: (data) => {
        return data.result?.result;
      },
      enabled: pinCode !== undefined,
    },
  );
  const rows = useMemo(() => {
    return data?.rows?.map((row: any) => ({
      id: row.id,
      action: row.isRefund ? 'REFUND' : 'RENEW',
      date: moment(row.createdAt).format('MMM Do, YYYY / hh:mm a'),
      device: row.intPkDeviceID,
    }));
  }, [data]);

  return (
    <Fragment>
      {isLoading && <DataTableSkeleton />}
      {!isLoading && rows ? (
        <DataTable
          isSortable
          rows={rows as any}
          headers={[
            { header: 'ID', key: 'id' },
            { header: 'Action', key: 'action' },
            { header: 'Date', key: 'date' },
            { header: 'Device ID', key: 'device' },
          ]}
        >
          {({ rows, headers, getHeaderProps, getTableProps, getToolbarProps, getBatchActionProps, selectedRows, getSelectionProps }: any) => (
            <TableContainer>
              {/* <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <TableToolbarSearch
                    persistent={true}
                    value={searchField}
                    onChange={(e) => {
                      setSearchField(e.target?.value);
                    }}
                  />
                </TableToolbarContent>
              </TableToolbar> */}
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => {
                    return (
                      <TableRow key={row.sr}>
                        {row.cells.map((cell: any) => {
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={Math.round(queryOptions.skip / queryOptions.take) + 1}
                pageNumberText="Page Number"
                pageSize={queryOptions.take}
                pageSizes={[10, 50, 100, 500, 1000]}
                totalItems={data.count}
                onChange={(data) => {
                  setQueryOptions((queryOptions) => {
                    const copy = { ...queryOptions };
                    copy.skip = (data.page - 1) * data.pageSize;
                    copy.take = data.pageSize;
                    return copy;
                  });
                }}
              />
            </TableContainer>
          )}
        </DataTable>
      ) : (
        'No data'
      )}
    </Fragment>
  );
};

const PinBatchBrands = ({ pinBatch }: { pinBatch: PinBatch }) => {
  const getBrands = useApiHandler({
    function: useApi().getBrands,
    config: {
      throwError: true,
    },
  });

  const deAssignBrands = useApiHandler({
    function: useApi().deAssignBrands,
    config: {
      throwError: true,
    },
  });

  const assignBrands = useApiHandler({
    function: useApi().assignBrands,
    config: {
      throwError: true,
    },
  });

  const [brandIds, setBrandIds] = useState([]);

  const [assignModalOpen, setAssignModalOpen] = useState(false);

  const [queryOptions, setQueryOptions] = useState({
    take: 10,
    skip: 0,
  });

  const { data, isLoading, refetch } = useQuery(['brands', pinBatch?.id], async () => getBrands(pinBatch.id), {
    select: (data) => {
      return data.result?.result;
    },
  });
  const rows = useMemo(() => {
    return data?.rows?.map((row: any) => ({
      id: row.intPkBrandID,
      brandName: row.brandName,
      remove: (
        <Button
          kind="ghost"
          onClick={() => {
            deAssignBrands([row.intPkBrandID], pinBatch?.id).then((e) => refetch());
          }}
        >
          De-Assign
        </Button>
      ),
    }));
  }, [data?.rows, deAssignBrands, pinBatch?.id, refetch]);

  const onRequestSubmit = async () => {
    await assignBrands(brandIds, pinBatch?.id);
    setAssignModalOpen(false);
    refetch();
    setBrandIds([]);
  };

  return (
    <Fragment>
      {!!assignModalOpen &&
        createPortal(
          <Modal
            key="assign-brand-modal"
            open={!!assignModalOpen}
            onRequestClose={() => {
              setAssignModalOpen(false);
              setBrandIds([]);
            }}
            onRequestSubmit={onRequestSubmit}
            hasForm={true}
            primaryButtonText="Assign"
            secondaryButtonText="Cancel"
            modalHeading={`Assign Brand`}
            modalLabel="Manage Batch"
            size="lg"
            style={{ zIndex: 70000 }}
          >
            <AssignBrandModal pinBatch={pinBatch} setBrandIds={setBrandIds} />
          </Modal>,
          document.getElementById('modal-root') || document.body,
        )}
      {isLoading && <DataTableSkeleton />}
      {!isLoading && rows ? (
        <DataTable
          isSortable
          rows={rows as any}
          headers={[
            { header: 'ID', key: 'id' },
            { header: 'brandName', key: 'brandName' },
            { header: '', key: 'remove' },
          ]}
        >
          {({ rows, headers, getHeaderProps, getTableProps, getToolbarProps, getBatchActionProps, selectedRows, getSelectionProps }: any) => (
            <TableContainer>
              <TableToolbar {...getToolbarProps()}>
                <TableToolbarContent>
                  <Button
                    size="small"
                    kind="primary"
                    onClick={() => {
                      setAssignModalOpen(true);
                    }}
                  >
                    Assign Brand
                  </Button>
                </TableToolbarContent>
              </TableToolbar>
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    {headers.map((header: any) => (
                      <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row: any) => {
                    return (
                      <TableRow key={row.sr}>
                        {row.cells.map((cell: any) => {
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Pagination
                backwardText="Previous page"
                forwardText="Next page"
                itemsPerPageText="Items per page:"
                page={Math.round(queryOptions.skip / queryOptions.take) + 1}
                pageNumberText="Page Number"
                pageSize={queryOptions.take}
                pageSizes={[10, 50, 100, 500, 1000]}
                totalItems={data.count}
                onChange={(data) => {
                  setQueryOptions((queryOptions) => {
                    const copy = { ...queryOptions };
                    copy.skip = (data.page - 1) * data.pageSize;
                    copy.take = data.pageSize;
                    return copy;
                  });
                }}
              />
            </TableContainer>
          )}
        </DataTable>
      ) : (
        'No data'
      )}
    </Fragment>
  );
};

const AssignBrandModal = ({ pinBatch, setBrandIds }: { pinBatch: PinBatch; setBrandIds: Function }) => {
  const getBrands = useApiHandler({
    function: useApi().getBrands,
    config: {
      throwError: true,
    },
  });

  const { data: brands, isLoading: areBrandsLoading } = useQuery(['brands', pinBatch?.id, true], async () => getBrands(pinBatch?.id, true), {
    select: (data) => {
      return data.result?.result;
    },
    enabled: pinBatch !== undefined,
  });
  const rows = useMemo(() => {
    return brands?.rows?.map((row: any) => ({
      id: row.intPkBrandID,
      brandName: row.brandName,
    }));
  }, [brands]);

  return (
    <div style={{ minHeight: '50vh' }}>
      <MultiSelect
        items={rows ?? []}
        id="brandIds"
        initialSelectedItems={[]}
        label="Brands"
        itemToString={(item: any) => (item ? item.brandName : '')}
        onChange={({ selectedItems }) => {
          setBrandIds(selectedItems.map((item: any) => item.id));
        }}
      />
    </div>
  );
};
