import React, { useContext } from 'react';
import { UserSessionInfo } from 'vezro-common';
import Api from '../api/Api';

interface AppContextType {
  user?: UserSessionInfo;
  login: (userName: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  api: Api;
}


const initialState: AppContextType = {
  api: new Api(),
  login: async () => {},
  logout: async () => {},
};

export const AppContext = React.createContext<AppContextType>(initialState);
export const useAppContext = () => useContext(AppContext);
export const useApi = () => {
  return useAppContext().api;
};
