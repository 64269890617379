import { Header, HeaderName, HeaderGlobalBar, Content } from 'carbon-components-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import UserProfileHeaderAction from '../../components/UserProfileHeaderAction/UserProfileHeaderAction';
import PinBatchesPage from './Partials/PinBatchesPage';

export const HomePagePath = '/';

export default function HomePage() {
  return (
    <div className="container">
      <Header aria-label="VezRo">
        <HeaderName href="#" prefix="Netelsoft">
          VezRo
        </HeaderName>
        <HeaderGlobalBar>
          <UserProfileHeaderAction />
        </HeaderGlobalBar>
      </Header>
      {/* <SideNav isFixedNav expanded>
        <SideNavItems>
          <SideNavMenu title="Pins" isActive>
            <Link to="/home/batches" style={{ textDecoration: 'none' }}>
              <SideNavMenuItem isActive>Batches</SideNavMenuItem>
            </Link>
            <SideNavMenuItem></SideNavMenuItem>
          </SideNavMenu>
        </SideNavItems>
      </SideNav> */}
      <Content className="content">
        <Switch>
          <Route path="/home" exact>
            <Redirect to="/home/batches" />
          </Route>
          <Route path="/home/batches">
            <PinBatchesPage />
          </Route>
        </Switch>
      </Content>
    </div>
  );
}
