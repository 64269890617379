import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { UserSessionInfo, Prisma, PinBatch, PinBatchRequest, Pin } from 'vezro-common';
import { ApiResponse } from '../typings/ApiResponse';
import { CreatePinBatchDto } from '../typings/dto/CreatePinBatch';
export class ApiError extends Error {
  constructor(message: string) {
    super(message);

    // Automatic Inheritence doesnt work? WTF js?
    Object.setPrototypeOf(this, ApiError.prototype);
    Error.captureStackTrace(this, ApiError);

    this.name = 'ApiError';
  }
}

export class UnauthenticatedError extends ApiError {
  constructor(message: string) {
    super(message);

    // Automatic Inheritence doesnt work? WTF js?
    Object.setPrototypeOf(this, UnauthenticatedError.prototype);
    Error.captureStackTrace(this, UnauthenticatedError);

    this.name = 'UnauthenticatedError';
  }
}

export default class Api {
  private axios: AxiosInstance;

  constructor() {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
  }

  private async request<T = any>(props: AxiosRequestConfig) {
    try {
      const result = await this.axios.request<ApiResponse<T>>(props);
      return result;
    } catch (e) {
      if (e instanceof Error) {
        const axiosError = e as AxiosError<ApiResponse<null>>;
        if (axiosError.response) {
          const errorMessage = axiosError.response?.data.message;

          if (axiosError.response?.status === 401) {
            throw new UnauthenticatedError(errorMessage);
          }

          throw new ApiError(errorMessage);
        }
      }

      throw e;
    }
  }

  async login(userName: string, password: string): Promise<UserSessionInfo | undefined> {
    const { data } = await this.request<UserSessionInfo>({
      url: '/auth/login',
      method: 'post',
      data: {
        userName,
        password,
      },
    });

    if (data.status === 200) {
      this.loginWithToken(data.result.token);
      return data.result;
    }
  }

  loginWithToken(token: string) {
    this.axios.defaults.headers.authorization = `Bearer ${token}`;
  }

  async logout(user: UserSessionInfo): Promise<void> {
    delete this.axios.defaults.headers.authorization;

    // TODO: invalidate refresh token
  }

  async getPinBatches(props: Prisma.PinBatchFindManyArgs) {
    return this.request<any>({
      url: '/getPinBatches',
      params: props,
    }).then((res) => res.data);
  }

  async getPins(props: Prisma.PinFindManyArgs) {
    return this.request<any>({
      url: '/getPins',
      params: props,
    }).then((res) => res.data);
  }

  async createPinBatch(props: CreatePinBatchDto) {
    return this.request<any[]>({
      url: '/generatePins',
      method: 'post',
      data: props,
    }).then((res) => res.data);
  }

  async editPinBatch(props: Partial<PinBatch>) {
    return this.request<any[]>({
      url: '/editPinBatch',
      method: 'put',
      data: props,
    }).then((res) => res.data);
  }

  async editPin(props: Partial<Pin>) {
    return this.request<any[]>({
      url: '/editPin',
      method: 'put',
      data: props,
    }).then((res) => res.data);
  }

  async renewPin(pinId: number, isRefund: boolean = false) {
    return this.request<any[]>({
      url: `/renewPin/${pinId}`,
      method: 'put',
      params: { isRefund: isRefund ? 1 : 0 },
    }).then((res) => res.data);
  }

  async getPinLogs(props: Prisma.PinRenewLogFindManyArgs) {
    return this.request<any>({
      url: `/getPinLogs/`,
      params: props,
    }).then((res) => res.data);
  }

  async getProfiles(props: Prisma.ProfileFindManyArgs) {
    return this.request<any>({
      url: '/getProfile',
      params: props,
    }).then((res) => res.data);
  }

  async getBrands(batchId?: number, reversed?: boolean) {
    return this.request<any>({
      url: '/getBrands',
      params: { batchId, reversed: reversed ? 1 : undefined },
    }).then((res) => res.data);
  }

  async assignBrands(brandIds: Array<number>, batchId: number) {
    return this.request<any>({
      url: '/assignBrands',
      method: 'put',
      data: { brandIds, batchId },
    }).then((res) => res.data);
  }

  async deAssignBrands(brandIds: Array<number>, batchId: number) {
    return this.request<any>({
      url: '/deassignBrands',
      method: 'put',
      data: { brandIds, batchId },
    }).then((res) => res.data);
  }

  async exportCSV(ids: Array<number>) {
    return this.request<any>({
      url: '/exportCSV',
      data: { ids },
      method: 'post',
    });
  }
}
