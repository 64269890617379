// Third Party Libs
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider as AlertProvider, transitions, positions } from 'react-alert';

// Pages
import HomePage, { HomePagePath } from './pages/Home';
import Login, { LoginPath } from './pages/Login';
import Page404 from './pages/Page404';

// Components
import PrivateRoute from './components/PrivateRoute';

// SCSS
import './App.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import Alert from './components/Alert/Alert';
import GlobalProvider from './store/GlobalProvider';

const alertOptions = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE,
  containerStyle: {
    zIndex: 100000,
  },
};

const App = () => {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <AlertProvider template={Alert} {...alertOptions}>
        <Router>
          <GlobalProvider>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/home" />
              </Route>
              <Route path={LoginPath} component={Login} />
              <PrivateRoute path={HomePagePath}>
                <HomePage />
              </PrivateRoute>
              <Route path="*" component={Page404} />
            </Switch>
          </GlobalProvider>
        </Router>
      </AlertProvider>
    </QueryClientProvider>
  );
};

export default App;
