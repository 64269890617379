import LoginForm from '../../components/LoginForm';
import { useHistory } from 'react-router-dom';
import { useAppContext } from '../../store';
import { useEffect } from 'react';

export const LoginPath = '/login';

export default function Login() {
  const history = useHistory();
  const { user } = useAppContext();

  useEffect(() => {
    if (user) {
      history.push('/home');
    }
  }, [user, history]);

  return (
    <div className="login-page">
      <div className="mb-5 text-center">
        <h1>
          <b>Welcome to VezRo</b>
        </h1>
        <p>TwinTurbo's Reseller Dashboard</p>
      </div>
      <LoginForm />
    </div>
  );
}
