import classNames from 'classnames';
import React from 'react';

type FlyoutProps = {
  open?: boolean;
  dark?: boolean;
};

export default function Flyout({ children, open = false, dark = false }: React.PropsWithChildren<FlyoutProps>) {
  return open ? <div className={classNames('flyout', { 'flyout-dark': dark })}>{children}</div> : null;
}
